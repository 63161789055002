<template>
  <div id="checkinActive" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <v-card id="dialog" class="TF type2" width="100%">
        <div class="b-content">
          <div class="B-dialogform step">
            <div class="t-namepage" style="text-align: center; font-size: 18px;">
              Medicine Declaration Form
            </div>
            <div class="b-form">
              <v-stepper v-model="stepdialog">
                <v-stepper-header>
                  <v-stepper-step :complete="stepdialog > 1" step="1"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepdialog > 2" step="2"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepdialog > 3" step="3"></v-stepper-step>
                </v-stepper-header>

                <v-stepper-items class="T-size-16">
                  <!-- Start step 1 -->
                  <v-stepper-content step="1" ref="focusStep1">
                    <div class="b-contentstep" style="color: white">
                      <div class="box-S4">
                        <div class="B-passenger">
                          <div class="b-person">
                            <div class="b-display">
                              <div class="B-display"></div>
                            </div>
                            <div class="b-name-company">
                              <p class="t-name">
                                {{ u_firstname }} {{ u_lastname }}
                              </p>
                              <div class="t-company">
                                Company (บริษัท) : {{ c_name }}
                              </div>
                              <div class="t-company">
                                Date (วันที่) : {{ dateNow }}
                              </div>
                              <div></div>
                            </div>
                          </div>
                          <div class="line"></div>

                          <p>
                            <b>
                              Please marks “A” or “B” in the following questions
                              (Recorded by passengers) and return this form to
                              the nurse</b>โปรดอ่านและทำเครื่องหมายวงกลม
                            ลงในข้อความที่ท่านคิดว่าถูกต้องเพียงข้อความเดียว
                            (บันทึกโดยผู้โดยสาร)และส่งกลับคืนพยาบาล
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="box-S4">
                      <div class="b-btnSave">
                        <v-btn class="theme-btn-even btn-save Bsize100" @click="GotoStepDialog2()">
                          <span class="T-size-16">NEXT</span>
                        </v-btn>
                      </div>
                    </div>
                  </v-stepper-content>
                  <!-- End step 1 -->

                  <!-- Start step 2 -->
                  <v-stepper-content step="2" ref="focusStep2">
                    <div class="b-contentstep" style="color: white">
                      <div class="box-S4">
                        <div class="N-Page">
                          Declaration of Medical History and Medication Used
                          Form (Recorded by Passengers)<br />แบบแจ้งประวัติการเจ็บป่วยและยาที่แพทย์สั่ง
                          (บันทึกโดยผู้โดยสาร)
                        </div>
                      </div>

                      <div class="box-S4">
                        1. Circulation (E.g. Hypertension)<br />ระบบการไหลเวียนโลหิต (เช่น ความดันโลหิตสูง)
                      </div>
                      <div class="box-S4 noPadding b-neme">
                        <v-radio-group v-model="mhf_medical_history1" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่เป็น" value="false"></v-radio>
                          <v-radio label="Yes เป็น" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      Bring Medicine with you?<br />นำยามาด้วยหรือไม่
                      <div class="box-S4">
                        <v-radio-group v-model="mhf_bring_medicine1" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่ได้นำมา" value="false"></v-radio>
                          <v-radio label="Yes นำมาด้วย" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="box-S4 noPadding b-neme" v-show="mhf_bring_medicine1 == 'true'">
                        <v-text-field class="hideMessage" label="Name of Medicine ชื่อยา" v-model="mhf_medicine_name1"
                          @input="CheckStep2()"></v-text-field>
                      </div>

                      <div class="box-S4 noPadding b-neme">
                        2. Endocrine (E.g. Diabetes, Thyroid)<br />ระบบต่อมไร้ท่อ (เช่น - เบาหวาน/เบาจืด ไทรอยด์)
                      </div>
                      <div class="box-S4 b-neme">
                        <v-radio-group v-model="mhf_medical_history2" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่เป็น" value="false"></v-radio>
                          <v-radio label="Yes เป็น" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      Bring Medicine with you?<br />นำยามาด้วยหรือไม่
                      <div class="box-S4 b-neme">
                        <v-radio-group v-model="mhf_bring_medicine2" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่ได้นำมา" value="false"></v-radio>
                          <v-radio label="Yes นำมาด้วย" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="box-S4 noPadding b-neme" v-show="mhf_bring_medicine2 == 'true'">
                        <v-text-field class="hideMessage" label="Name of Medicine ชื่อยา" v-model="mhf_medicine_name2"
                          @input="CheckStep2()"></v-text-field>
                      </div>

                      <div class="box-S4 noPadding b-neme">
                        3. Neurological/Psychiatric (E.g. depression, epilepsy,
                        seizure, dizziness, fainting, nervousness, anxiety,
                        stroke)<br />ระบบประสาทและสมอง (เช่น. อาการซึมเศร้า ชักเกร็ง ลมบ้าหมู วิงเวียนศรีษะ
                        วูบหรือหมดสติ ความผิดปกติของเส้นประสาท ภาวะเครียด ความผิดปกติของเส้นเลือดในสมอง)
                      </div>
                      <div class="box-S4 b-neme">
                        <v-radio-group v-model="mhf_medical_history3" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่เป็น" value="false"></v-radio>
                          <v-radio label="Yes เป็น" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      Bring Medicine with you?<br />นำยามาด้วยหรือไม่
                      <div class="box-S4 b-neme">
                        <v-radio-group v-model="mhf_bring_medicine3" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่ได้นำมา" value="false"></v-radio>
                          <v-radio label="Yes นำมาด้วย" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="box-S4 noPadding b-neme" v-show="mhf_bring_medicine3 == 'true'">
                        <v-text-field class="hideMessage" label="Name of Medicine ชื่อยา" v-model="mhf_medicine_name3"
                          @input="CheckStep2()"></v-text-field>
                      </div>

                      <div class="box-S4 noPadding b-neme">
                        4. Cardiac (E.g. angina, heart attack, heart murmur, congenital heart lesions, heart surgery,
                        prosthetic heart valve(s), pacemaker)
                        <br />ระบบหัวใจและหลอดเลือด (เช่น. อาการเจ็บหน้าอก หัวใจวาย หัวใจเต้นผิดจังหวะ
                        หัวใจพิการแต่กำเนิด การผ่าตัดหัวใจ ความผิดปกติของลิ้นหัวใจ ใส่เครื่องกระตุ้นการเต้นของหัวใจ)
                      </div>
                      <div class="box-S4 b-neme">
                        <v-radio-group v-model="mhf_medical_history4" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่เป็น" value="false"></v-radio>
                          <v-radio label="Yes เป็น" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      Bring Medicine with you?<br />นำยามาด้วยหรือไม่
                      <div class="box-S4 b-neme">
                        <v-radio-group v-model="mhf_bring_medicine4" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่ได้นำมา" value="false"></v-radio>
                          <v-radio label="Yes นำมาด้วย" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="box-S4 noPadding b-neme" v-show="mhf_bring_medicine4 == 'true'">
                        <v-text-field class="hideMessage" label="Name of Medicine ชื่อยา" v-model="mhf_medicine_name4"
                          @input="CheckStep2()"></v-text-field>
                      </div>

                      <div class="box-S4 noPadding b-neme">
                        5. Any other condition not listed above
                        <br />ประวัติการเจ็บป่วย หรือความผิดปกติอื่นๆ โปรดระบุ
                      </div>
                      <div class="box-S4 b-neme">
                        <v-radio-group v-model="mhf_medical_history5" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่เป็น" value="false"></v-radio>
                          <v-radio label="Yes เป็น" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      Bring Medicine with you?<br />นำยามาด้วยหรือไม่
                      <div class="box-S4 b-neme">
                        <v-radio-group v-model="mhf_bring_medicine5" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่ได้นำมา" value="false"></v-radio>
                          <v-radio label="Yes นำมาด้วย" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="box-S4 noPadding b-neme" v-show="mhf_bring_medicine5 == 'true'">
                        <v-text-field class="hideMessage" label="Name of Medicine ชื่อยา" v-model="mhf_medicine_name5"
                          @input="CheckStep2()"></v-text-field>
                      </div>

                      <div class="box-S4 noPadding">
                        6. For Female only are you currently pregnant or suspecting of pregnant?
                        <br />สำหรับสุภาพสตรี ขณะนี้คุณกำลังตั้งครรภ์ หรือสงสัยว่ากำลังตั้งครรภ์หรือไม่?
                      </div>
                      <div class="box-S4 b-neme">
                        <v-radio-group v-model="mhf_medical_history6" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่เป็น" value="false"></v-radio>
                          <v-radio label="Yes เป็น" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      Bring Medicine with you?<br />นำยามาด้วยหรือไม่
                      <div class="box-S4 b-neme">
                        <v-radio-group v-model="mhf_bring_medicine6" v-on:change="CheckStep2()">
                          <v-radio label="No ไม่ได้นำมา" value="false"></v-radio>
                          <v-radio label="Yes นำมาด้วย" value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="box-S4 noPadding b-neme" v-show="mhf_bring_medicine6 == 'true'">
                        <v-text-field class="hideMessage" label="Name of Medicine ชื่อยา" v-model="mhf_medicine_name6"
                          @input="CheckStep2()"></v-text-field>
                      </div>
                    </div>

                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="BackStepDialog(1)">
                        <span class="T-size-16">BACK</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-save Bsize100" text v-show="step3" @click="GotoStepDialog3()">
                        <span class="T-size-16">NEXT</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <!-- End step 3-->

                  <!-- Start step 3 -->
                  <v-stepper-content step="3" ref="focusStep3">
                    <div class="b-contentstep" style="color: white">
                      <!-- <div class="box-S4">
                        <div class="N-Page T-size-24">
                          Medical History<br />(ประวัติการเจ็บป่วย)
                        </div>
                      </div> -->

                      <div class="box-S4 b-neme">
                        <div class="box-s4">
                          <p>
                            <b>
                              Declaration and Consent for release of
                              information; I hereby certify that my answers to
                              the questions are complete, accurate and no
                              information has been withheld. I understand that
                              if this is later shown not to be the case it may
                              result in cancellation of an offshore trip, the
                              offer of employment being withdrawn or
                              reconsideration of my suitability to continue with
                              my employment.</b>
                          </p>
                          <p>
                            คำแถลงและยินยอมเปิดเผยข้อมูล;
                            ข้าพเจ้ายอมรับว่าคำตอบที่ให้ไว้ถูกต้องสมบูรณ์
                            และเข้าใจว่าถ้าไม่เป็นจริงอาจมีผลต่อการปฎิเสธการเดินทาง
                            และอาจมีผลต่อการจ้างงานของข้าพเจ้า
                          </p>
                          <v-checkbox v-model="consented"
                            :label="`I agree to give consent to disclose the result of this questionnaire information to Valeura Energy (Thailand) Ltd.`">
                          </v-checkbox>
                          <p style="padding-left: 30px">
                            ข้าพเจ้ายินดีให้ความยินยอมเปิดเผยช้อมูลนี้แก่
                            บริษัท แวลูร่า เอนเนอร์ยี่ (ประเทศไทย) จำกัด
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="BackStepDialog(2)">
                        <span class="T-size-18">BACK</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialogConfirm = true"
                        v-show="consented">
                        <span class="T-size-18">CONFIRM</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <!-- End step 3-->
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </v-card>

      <v-dialog v-model="dialogConfirm" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog save">
              <div class="t-title">Confirm Information</div>
              <!-- <div class="t-des"></div> -->
              <div class="box-S4"></div>
            </div>
            <div class="box-btn">
              <v-btn @click="dialogConfirm = false" class="theme-btn-even btn-cancel Bsize100" text>
                <span class="T-size-16">NO</span>
              </v-btn>
              <v-btn @click="SaveToDB()" class="theme-btn-even btn-save Bsize100" text>
                <span class="T-size-16">YES</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSaveComplete" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog confirm">
              <div class="t-title">Save Completed</div>
            </div>
            <div class="box-btn">
              <!-- <v-btn class="theme-btn-even btn-ok Bsize100" style="width: 100%;" text :to="{
                name: 'MubadalaForCustomerEForm2',
                params: { id: this.$route.params.id },
              }">
                <span class="T-size-18">OK</span>
              </v-btn> -->
              <v-btn class="theme-btn-even btn-ok Bsize100" style="width: 100%;" text :to="{
                name: 'MubadalaForCustomerEForm3',
                params: { id: this.$route.params.id },
              }">
                <span class="T-size-18">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { format } from "date-fns";

export default {
  data: () => ({
    stepdialog: 1,

    //Step I Start
    step2: false,
    dateNow: format(new Date(), "dd-MMM-yyyy"),
    u_firstname: "",
    u_lastname: "",
    c_name: "",
    o_name: "",
    //Step I End

    //Step II Start
    step3: false,
    mhf_medical_history1: null,
    mhf_bring_medicine1: null,
    mhf_medicine_name1: "",
    mhf_medical_history2: null,
    mhf_bring_medicine2: null,
    mhf_medicine_name2: "",
    mhf_medical_history3: null,
    mhf_bring_medicine3: null,
    mhf_medicine_name3: "",
    mhf_medical_history4: null,
    mhf_bring_medicine4: null,
    mhf_medicine_name4: "",
    mhf_medical_history5: null,
    mhf_bring_medicine5: null,
    mhf_medicine_name5: "",
    mhf_medical_history6: null,
    mhf_bring_medicine6: null,
    mhf_medicine_name6: "",
    //Step II End

    //Step IIII Start
    consented: false,
    dialogConfirm: false,
    dialogSaveComplete: false,
    //Step IIII End

    flightId: null,
  }),

  components: {},
  async mounted() {
    let q = {};
    q.p_id = this.$route.params.id;
    let res = await feathersClientUOA
      .service("viewcheckinpassenger-2")
      .find({ query: q });
    let user = res.data[0];
    this.flightId = user.f_id;
    if (user.mhf_status != 'No Data') {
      // if (user.mcf_status == 'No Data') {
      //   this.$router.push({
      //     path: `/mubadalaforcustomereform2/${this.$route.params.id}`,
      //   });
      // }
      // else 
      if (user.maf_status == 'No Data') {
        this.$router.push({
          path: `/mubadalaforcustomereform3/${this.$route.params.id}`,
        });
      }
      // else if (user.mcf_pass_signature == null || user.mhf_pass_singnature == null || user.maf_pass_signature == null) {
        else if (user.mhf_pass_singnature == null || user.maf_pass_signature == null) {
        this.$router.push({
          path: `/MubadalaForCustomerEFormSignature/${this.$route.params.id}`,
        });
      }
      else {
        this.$router.push({
          path: `/mubadalaforcustomereformthankyou`,
        });
      }
    }


    this.u_firstname = user.u_firstname;
    this.u_lastname = user.u_lastname;
    this.c_name = user.c_name;
    this.o_name = user.o_name;
  },
  methods: {
    GotoStepDialog2() {
      this.stepdialog = 2;
      this.GotoFocusStep('focusStep2')
    },
    CheckStep2() {
      let case1 = false;
      let case2 = false;
      let case3 = false;
      let case4 = false;
      let case5 = false;
      let case6 = false;
      if (
        this.mhf_medical_history1 != null &&
        this.mhf_bring_medicine1 != null
      ) {
        if (this.mhf_bring_medicine1 == "true") {
          if (this.mhf_medicine_name1 != "") {
            case1 = true;
          }
        } else {
          this.mhf_medicine_name1 = "";
          case1 = true;
        }
      }

      if (
        this.mhf_medical_history2 != null &&
        this.mhf_bring_medicine2 != null
      ) {
        if (this.mhf_bring_medicine2 == "true") {
          if (this.mhf_medicine_name2 != "") {
            case2 = true;
          }
        } else {
          this.mhf_medicine_name2 = "";
          case2 = true;
        }
      }

      if (
        this.mhf_medical_history3 != null &&
        this.mhf_bring_medicine3 != null
      ) {
        if (this.mhf_bring_medicine3 == "true") {
          if (this.mhf_medicine_name3 != "") {
            case3 = true;
          }
        } else {
          this.mhf_medicine_name3 = "";
          case3 = true;
        }
      }

      if (
        this.mhf_medical_history4 != null &&
        this.mhf_bring_medicine4 != null
      ) {
        if (this.mhf_bring_medicine4 == "true") {
          if (this.mhf_medicine_name4 != "") {
            case4 = true;
          }
        } else {
          this.mhf_medicine_name4 = "";
          case4 = true;
        }
      }

      if (
        this.mhf_medical_history5 != null &&
        this.mhf_bring_medicine5 != null
      ) {
        if (this.mhf_bring_medicine5 == "true") {
          if (this.mhf_medicine_name5 != "") {
            case5 = true;
          }
        } else {
          this.mhf_medicine_name5 = "";
          case5 = true;
        }
      }

      if (
        this.mhf_medical_history6 != null &&
        this.mhf_bring_medicine6 != null
      ) {
        if (this.mhf_bring_medicine6 == "true") {
          if (this.mhf_medicine_name6 != "") {
            case6 = true;
          }
        } else {
          this.mhf_medicine_name6 = "";
          case6 = true;
        }
      }
      if (case1 && case2 && case3 && case4 && case5 && case6) {
        this.step3 = true;
      } else {
        this.step3 = false;
      }
    },
    GotoStepDialog3() {
      this.stepdialog = 3;
      this.GotoFocusStep('focusStep3')
    },
    async SaveToDB() {
      try {
        let q = {};
        q.p_id = this.$route.params.id;

        let res = await feathersClientUOA
          .service("mubadala-health-form-2")
          .find({ query: q });

        if (res.total == 1) {
          let mhf_id = res.data[0].mhf_id;
          let dataUpdate = {
            mhf_medical_history1: this.mhf_medical_history1,
            mhf_bring_medicine1: this.mhf_bring_medicine1,
            mhf_medicine_name1: this.mhf_medicine_name1,
            mhf_medical_history2: this.mhf_medical_history2,
            mhf_bring_medicine2: this.mhf_bring_medicine2,
            mhf_medicine_name2: this.mhf_medicine_name2,
            mhf_medical_history3: this.mhf_medical_history3,
            mhf_bring_medicine3: this.mhf_bring_medicine3,
            mhf_medicine_name3: this.mhf_medicine_name3,
            mhf_medical_history4: this.mhf_medical_history4,
            mhf_bring_medicine4: this.mhf_bring_medicine4,
            mhf_medicine_name4: this.mhf_medicine_name4,
            mhf_medical_history5: this.mhf_medical_history5,
            mhf_bring_medicine5: this.mhf_bring_medicine5,
            mhf_medicine_name5: this.mhf_medicine_name5,
            mhf_medical_history6: this.mhf_medical_history6,
            mhf_bring_medicine6: this.mhf_bring_medicine6,
            mhf_medicine_name6: this.mhf_medicine_name6,
            mhf_consent: true,
            mhf_status: "Wait",
          };
          await feathersClientUOA
            .service("mubadala-health-form-2")
            .patch(mhf_id, dataUpdate);

          this.dialogConfirm = false;
          this.dialogSaveComplete = true;

          //SEQ LOG
          let properties = {
            f_id: parseInt(this.flightId),
            p_id: parseInt(this.$route.params.id),
            username: this.u_firstname + "  " + this.u_lastname,
            mhf_id: parseInt(mhf_id)
          };
          this.$log.Info('{action} by {username} (Passenger) : f_id={f_id}, p_id={p_id}, mhf_id={mhf_id}', { action: "CreateMubadalaHelthForm", ...properties });
        }
      } catch (error) {
        //SEQ LOG
        this.$log.Error("CreateMubadalaHelthForm, Error : {error}", { error });
        console.log("CreateMubadalaHelthForm, Error : {error}" + error);
      }
      //Get
    },

    GotoFocusStep(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },

    BackStepDialog(step) {
      if (step == 1) {
        this.GotoFocusStep('focusStep1')
      }
      if (step == 2) {
        this.GotoFocusStep('focusStep2')
      }
      if (step == 3) {
        this.GotoFocusStep('focusStep3')
      }
      this.stepdialog = step;
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
}

.t-company {
  font-size: 12px;
}
</style>>
